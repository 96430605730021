var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0"
  }, _vm._l(_vm.limit, function (depth, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-3 py-sm-0",
      attrs: {
        "cols": "12",
        "md": 12 / _vm.limit,
        "sm": 12 / _vm.limit
      }
    }, [_c('v-select', {
      attrs: {
        "items": _vm.items(depth),
        "item-text": "name[ko]",
        "item-value": "_id",
        "value": _vm.select(depth),
        "label": `${depth}차 카테고리`,
        "placeholder": " ",
        "dense": _vm.dense,
        "hide-details": "",
        "disabled": !!_vm.allowDepth.length && !_vm.allowDepth.includes(depth)
      },
      on: {
        "input": _vm.input
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }